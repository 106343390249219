
// This script shows a preloader on page load
window.addEventListener('load', () => {
    const preloader = document.querySelector('.preloader');
  
    setTimeout(() => {
        preloader.classList.add('show-preloader');
        document.body.classList.remove('scrollbar-none');
    }, 1500);
});
