
// Navigation and its functionalities
let x;

(x = () => {
    const burgerMenu = document.querySelector('.burger-menu');
    const navMenu = document.querySelector('.nav-menu');
    const menuOverlay = document.querySelector('.menu-overlay');
    const mediaSize = 991;

    burgerMenu.addEventListener('click', toggleNav);
    menuOverlay.addEventListener('click', toggleNav);
    
    function toggleNav() {
        burgerMenu.classList.toggle('open');  
        navMenu.classList.toggle('open');
        menuOverlay.classList.toggle('active'); 
    }

    navMenu.addEventListener('click', (event) => {
        if(event.target.hasAttribute('data-toggle') && window.innerWidth <= mediaSize) {
            event.preventDefault();
            const menuItemHasChildren = event.target.parentElement;
            if(menuItemHasChildren.classList.contains('active')) {
                collapseSubMenu();
            }
            else {
                if(navMenu.querySelector('.menu-item-has-children.active')) {
                    collapseSubMenu();
                }
                menuItemHasChildren.classList.add('active');
                const subMenu = menuItemHasChildren.querySelector('.sub-menu');
                subMenu.style.maxHeight = subMenu.scrollHeight + 'px';
            }
        }
    });

    function collapseSubMenu(){
        navMenu.querySelector('.menu-item-has-children.active .sub-menu').removeAttribute('style');
        navMenu.querySelector('.menu-item-has-children.active').classList.remove('active');
    }

    function resizeFix(){
        if(navMenu.classList.contains('open')) {
            toggleNav();
        }
        if(navMenu.querySelector('.menu-item-has-children.active')) {
            collapseSubMenu();
        }
    }

    window.addEventListener('resize', function() {
        if(this.innerWidth > mediaSize) {
            resizeFix();
        }
    });
})();
