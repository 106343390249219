// Create a Leaflet map
const map = L.map('map').setView([53.37766, 14.62181], 18);

// Add OpenStreetMap tiles
const osmTiles = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 18,
    attribution: '&copy; <a href="http://www.openstreetmap.org/copyright" target="_blank" title="OpenStreetMap">OpenStreetMap</a>'
});

// Default to setting OpenStreetMap as the active layer
osmTiles.addTo(map);

// Customized marker icon with color #a1e00f
const schoolMarkerIcon = L.divIcon({
    className: 'custom-marker',
    html: `<i style="font-size: 3rem; color: #1f211b;" class="fa-solid fa-location-dot"></i>`,
    iconAnchor: [17, 37]
});

// Add a marker for the school
const schoolMarker = L.marker([53.37766, 14.62181], { icon: schoolMarkerIcon })
    .addTo(map)
    .bindPopup(`
        <div class="h6 mb-2"><strong>Centrum Edukacji Zdroje</strong></div>
        <div style="font-weight: 500;">ul. Jabłoniowa 15, 70-761 Szczecin</div></div>
        <div style="font-weight: 500;">sekretariat@zdroje.edu.pl</div>
        <div style="font-weight: 500;">Tel. 91 461 33 03 lub 453 697 218</div>
        <div class="mt-2">Dołącz do naszej społeczności, działającej od 1998 roku. Dbamy o każdy aspekt, aby zapewnić wyjątkowe doświadczenia naszym uczniom.</div>
    `).openPopup();

// Add a circular area (school premises)
const schoolArea = L.circle([53.37766, 14.62181], {
    color: '#a1e00f', 
    fillColor: '#a1e00f',
    fillOpacity: 0.5, 
    radius: 25 
}).addTo(map);
