
// Selecting all elements with the class 'validation-info'
const validationInfoElements = document.querySelectorAll('.validation-info');

// Selecting input elements by their IDs
const firstNameInput = document.querySelector('#first-name');
const lastNameInput = document.querySelector('#last-name');
const emailInput = document.querySelector('#email');
const phoneNumberInput = document.querySelector('#phone-number');
const subjectInput = document.querySelector('#subject');
const messageInput = document.querySelector('#message');
const fileInput = document.querySelector('#files');
const rodoCheckbox = document.querySelector('#rodo');
const mathResultElement = document.querySelector('#result');

// Selecting buttons with the classes 'js-send-button' and 'js-reset-button'
const sendButton = document.querySelector('.js-send-button');
const resetButton = document.querySelector('.js-reset-button');

// Defining constants for minimum and maximum input lengths
const minLength = 3;
const maxLength = 30;

// Function to set the state "correct"
function setValidState(inputElement, validationInfoElement) {
    inputElement.classList.remove('is-invalid');
    inputElement.classList.add('is-valid');
    validationInfoElement.classList.remove('incorrect-input');
    validationInfoElement.classList.add('correct-input');
}  

// Function to set the state "invalid"
function setInvalidState(inputElement, validationInfoElement) {
    inputElement.classList.remove('is-valid');
    inputElement.classList.add('is-invalid');
    validationInfoElement.classList.remove('correct-input');
    validationInfoElement.classList.add('incorrect-input');
}

// Clears validation-related classes and content from input and validation info elements
function clearValidation(inputElement, validationInfoElement) {
    inputElement.classList.remove('is-valid', 'is-invalid');
    inputElement.value = '';
    validationInfoElement.classList.remove('correct-input', 'incorrect-input');
    validationInfoElement.textContent = '';
}

// Enables the send button by removing 'disabled' attribute and adding visual effects
function enableSendButton(sendButton) {
    sendButton.disabled = false;
    sendButton.classList.remove('disabled');
    sendButton.classList.add('color-dark-gray', 'slide-left');
}

// Disables the send button by setting 'disabled' attribute and adjusting its styling
function disableSendButton(sendButton) {
    sendButton.disabled = true;
    sendButton.classList.remove('color-dark-gray', 'slide-left');
    sendButton.classList.add('disabled');
}

// Name validation function
function validateName(inputElement, validationInfoElement, minLength, maxLength) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(inputValue === '') {
        validationInfoElement.textContent = 'To pole jest wymagane.';
        isValid = false;
    } else if(inputValue.length < minLength) {
        validationInfoElement.textContent = `Wprowadź przynajmniej ${minLength} znaki.`;
        isValid = false;
    } else if(inputValue.length > maxLength) {
        validationInfoElement.textContent = `Wprowadź nie więcej niż ${maxLength} znaków.`;
        isValid = false;
    } else if(!isValidName(inputValue)) {
        validationInfoElement.textContent = 'Pole może zawierać tylko litery i spacje.';
        isValid = false;
    } else if(inputValue.trim() !== inputValue) {
        validationInfoElement.textContent = 'Pole nie może zaczynać się lub kończyć spacją.';
        isValid = false;
    } else if(!/^[A-ZŻŹĆĄŚĘŁÓŃ]/.test(inputValue)) {
        validationInfoElement.textContent = 'Pole powinno zaczynać się od wielkiej litery.';
        isValid = false;
    } else {
        validationInfoElement.textContent = 'Wprowadzone dane są poprawne.';
        isValid = true;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

function isValidName(nameInput) {
    const namePattern = /^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ ]+$/;

    return namePattern.test(nameInput);
}

// Email validation function
function validateEmail(inputElement, validationInfoElement) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(inputValue === '') {
        validationInfoElement.textContent = 'To pole jest wymagane.';
        isValid = false;
    } else if(!isValidEmail(inputValue)) {
        validationInfoElement.textContent = 'Niepoprawny format adresu e-mail.';
        isValid = false;
    } else {
        validationInfoElement.textContent = 'Wprowadzony adres e-mail jest poprawny.';
        isValid = true;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

function isValidEmail(emailInput) {
    const emailPattern = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    return emailPattern.test(emailInput);
}

// Phone number validation function
function validatePhoneNumber(inputElement, validationInfoElement) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(!isValidPhoneNumber(inputValue)) {
        validationInfoElement.textContent = 'Niepoprawny format numeru telefonu.';
        isValid = false;
    } else {
        validationInfoElement.textContent = 'Wprowadzony numer telefonu jest poprawny.';
        isValid = true;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

function isValidPhoneNumber(phoneNumberInput) {
    const phoneNumberPattern = /^\+\d{1,3}\.\d{4,14}$/;

    return phoneNumberPattern.test(phoneNumberInput);
}

// Subject validation function
function validateSubject(inputElement, validationInfoElement) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(inputValue === '') {
        validationInfoElement.textContent = 'To pole jest wymagane.';
        isValid = false;
    } else if(inputValue.length < 5) {
        validationInfoElement.textContent = 'Wprowadź przynajmniej 5 znaków.';
        isValid = false;
    } else if(inputValue.length > 100) {
        validationInfoElement.textContent = 'Wprowadź nie więcej niż 100 znaków.';
        isValid = false;
    } else if(!isValidSubject(inputValue)) {
        validationInfoElement.textContent = 'Pole może zawierać tylko litery, cyfry i spacje.';
        isValid = false;
    } else {
        validationInfoElement.textContent = 'Wprowadzony temat jest poprawny.';
        isValid = true;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

function isValidSubject(subjectInput) {
    const subjectPattern = /^[\w\sżźćńółęąśŻŹĆĄŚĘŁÓŃ]+$/u;

    return subjectPattern.test(subjectInput);
}

// Message validation function
function validateMessage(inputElement, validationInfoElement) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(inputValue === '') {
        validationInfoElement.textContent = 'To pole jest wymagane.';
        isValid = false;
    } else if(inputValue.length < 10) {
        validationInfoElement.textContent = 'Wprowadź przynajmniej 10 znaków.';
        isValid = false;
    } else if(inputValue.length > 500) {
        validationInfoElement.textContent = 'Wprowadź nie więcej niż 500 znaków.';
        isValid = false;
    } else {
        validationInfoElement.textContent = 'Wprowadzona wiadomość jest poprawna.';
        isValid = true;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

// Checkbox validation function
function validateCheckbox(inputElement, validationInfoElement) {
    let isValid = true;

    if(inputElement.checked) {
        validationInfoElement.textContent = '';
        isValid = true;
    } else {
        validationInfoElement.textContent = 'Zaakceptuj RODO, aby kontynuować.';
        isValid = false;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

// Generate numbers for mathematical operations function
function generateMathOperation() {
    const min = 1;
    const max = 50;
    const operators = ['+', '-'];

    const randomNumberA = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomNumberB = Math.floor(Math.random() * (max - min + 1)) + min;
    const randomOperator = operators[Math.floor(Math.random() * operators.length)];

    let mathResultElement;
    switch(randomOperator) {
        case '+':
            mathResultElement = randomNumberA + randomNumberB;
            break;
        case '-':
            mathResultElement = randomNumberA - randomNumberB;
            break;
        default:
            mathResultElement = randomNumberA + randomNumberB;
    }

    const mathematicalOperation = document.querySelector('#mathematical-operation');
    mathematicalOperation.textContent = `${randomNumberA} ${randomOperator} ${randomNumberB} = ? *`;

    return mathResultElement;
}

// Math result validation function
function validateMathResult(inputElement, validationInfoElement, mathResultElement) {
    const inputValue = inputElement.value.trim();
    let isValid = true;

    if(inputValue === '') {
        validationInfoElement.textContent = 'To pole jest wymagane.';
        isValid = false;
    } else if(parseInt(inputValue) === mathResultElement) {
        validationInfoElement.textContent = 'Wprowadzona odpowiedź jest poprawna.';
        isValid = true;
    } else {
        validationInfoElement.textContent = 'Odpowiedź jest niepoprawna.';
        isValid = false;
    }

    if(isValid) {
        setValidState(inputElement, validationInfoElement);
    } else {
        setInvalidState(inputElement, validationInfoElement);
    }

    return isValid;
}

// This function checks the size of selected files in a file input element and displays an error message if any file exceeds the maximum allowed size
function checkFileSize(validationInfoElement) {
    const maxFileSizeMB = 10;
    const files = fileInput.files;

    let isError = false;
    let errorMessage = '';

    for(let i = 0; i < files.length; i++) {
        const file = files[i];
        if(!file) continue;

        const fileSizeMB = file.size / (1024 * 1024);
        if(fileSizeMB > maxFileSizeMB) {
            fileInput.value = '';
            isError = true;
            errorMessage = 'Plik o nazwie ' + (file.name || 'Nieznany plik') + ' przekracza maksymalny rozmiar ' + maxFileSizeMB + ' MB.';
            break;
        }
    }

    if(isError) {
        validationInfoElement.textContent = errorMessage;
        validationInfoElement.classList.remove('correct-input');
        validationInfoElement.classList.add('incorrect-input');
    } else {
        validationInfoElement.textContent = '';
        validationInfoElement.classList.remove('incorrect-input');
        validationInfoElement.classList.add('correct-input');
    }
}

function validateForm() {
    const formFields = [
        { inputElement: firstNameInput, validationInfoElementIndex: 0, validator: validateName.bind(null, firstNameInput, validationInfoElements[0], minLength, maxLength) },
        { inputElement: lastNameInput, validationInfoElementIndex: 1, validator: validateName.bind(null, lastNameInput, validationInfoElements[1], minLength, maxLength) },
        { inputElement: emailInput, validationInfoElementIndex: 2, validator: validateEmail.bind(null, emailInput, validationInfoElements[2]) },
        { inputElement: subjectInput, validationInfoElementIndex: 4, validator: validateSubject.bind(null, subjectInput, validationInfoElements[4]) },
        { inputElement: messageInput, validationInfoElementIndex: 5, validator: validateMessage.bind(null, messageInput, validationInfoElements[5]) },
        { inputElement: rodoCheckbox, validationInfoElementIndex: 7, validator: validateCheckbox.bind(null, rodoCheckbox, validationInfoElements[7]) },
        { inputElement: mathResultElement, validationInfoElementIndex: 8, validator: validateMathResult.bind(null, mathResultElement, validationInfoElements[8], generateMathOperation()) }
    ];

    let allFieldsValid = false; 

    const updateButtonState = () => {
        allFieldsValid = formFields.every(field => field.validator());
        const isDisabled = !allFieldsValid;

        sendButton.disabled = isDisabled;
        sendButton.classList.toggle('disabled', isDisabled);
        sendButton.classList.toggle('color-dark-gray', !isDisabled);
        sendButton.classList.toggle('slide-left', !isDisabled);
    };

    const handleInputChange = (validator) => () => {
        const isValidField = validator();
        updateButtonState();
    };

    const handleResetClick = () => {
        formFields.forEach(({ inputElement, validationInfoElementIndex }) => {
            inputElement.classList.remove('is-valid', 'is-invalid');
            inputElement.value = '';

            const validationInfoElement = validationInfoElements[validationInfoElementIndex];
            validationInfoElement.classList.remove('correct-input', 'incorrect-input');
            validationInfoElement.textContent = '';
        });

        phoneNumberInput.classList.remove('is-valid', 'is-invalid');
        phoneNumberInput.value = '';

        const optionalValidationInfoElement = validationInfoElements[3];
        optionalValidationInfoElement.classList.remove('correct-input', 'incorrect-input');
        optionalValidationInfoElement.textContent = '';

        disableSendButton(sendButton);
    };

    phoneNumberInput.addEventListener('input', () => {
        const isValidPhoneNumber = validatePhoneNumber(phoneNumberInput, validationInfoElements[3]);
    
        if(phoneNumberInput.value === '') {
            clearValidation(phoneNumberInput, validationInfoElements[3]);

            if(allFieldsValid) {
                enableSendButton(sendButton);
            }
        } else if(isValidPhoneNumber && allFieldsValid) {
            enableSendButton(sendButton);
        } else {
            disableSendButton(sendButton);
        }
    });
    
    fileInput.addEventListener('change', () => {
        checkFileSize(validationInfoElements[6]);
    });

    formFields.forEach(({ inputElement, validator }) => {
        inputElement.addEventListener('input', handleInputChange(validator));
    });

    resetButton.addEventListener('click', handleResetClick);
}

validateForm();
